import { Grid } from '@mui/material';
import React from 'react';

import StaticFaq from '@/components/StaticFaq/StaticFaq';
import Team from '@/components/Team/Team';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

/**
 * A component that serves Faq and Team components together.
 */
const FaqTeam = () => {
  const mobileMediaQuery = useMobileMediaQuery();

  return (
    <Grid
      container
      spacing={2}
      direction={mobileMediaQuery ? 'column-reverse' : 'row'}>
      <Grid item xs={12} md={7}>
        <StaticFaq />
      </Grid>
      <Grid item xs={12} md={5}>
        <Team />
      </Grid>
    </Grid>
  );
};

export default FaqTeam;
