import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(8),
  marginRight: theme.spacing(1.5),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3.5),
    marginRight: theme.spacing(0),
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  marginTop: theme.spacing(4),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
  },
}));

export const Questions = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(3.5),

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const More = styled('div')(() => ({
  marginTop: 32,
  display: 'flex',
  alignItems: 'flex-start',
  userSelect: 'none',
}));

export const MoreText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const QuestionWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const Question = styled('div')(() => ({
  userSelect: 'none',
  display: 'flex',
  alignItems: 'flex-start',
}));

export const Answer = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(3.7),
}));

export const QuestionIcon = styled('div')(({ theme }) => ({
  position: 'relative',
  marginRight: theme.spacing(1),
  cursor: 'pointer',
}));
