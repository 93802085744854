import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';

import BaseReloadButtonIcon from './lib/ReloadButtonIcon';

const rotateAnimation = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  background: theme.palette.common.white,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.2s ease-in-out',
  boxShadow:
    '0 1px 1px 0 rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06)',

  [theme.breakpoints.up('md')]: {
    '&:hover': {
      boxShadow:
        '0 4px 4px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06)',
    },
  },
}));

export const ReloadButtonWrapper = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.2s ease-in-out',

  [theme.breakpoints.up('md')]: {
    '&:hover': {
      transform: 'rotate(90deg)',
    },
  },
}));

export const ReloadButtonIcon = styled(BaseReloadButtonIcon, {
  shouldForwardProp: prop => prop !== 'rotate',
})(({ rotate }) => ({
  ...(rotate && {
    animationName: rotateAnimation,
    animationDuration: '0.5s',
  }),
}));
