import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';
import NextImage from 'next/image';

import Paper from '@/components/ui/Paper/Paper';

export const Wrapper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(8),

  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
  },
}));

export const ReloadButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.5),
  top: theme.spacing(2.5),

  [theme.breakpoints.down('md')]: {
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

export const Headline = styled(Typography)(() => ({
  margin: 0,
}));

const fadeOutIn = keyframes`
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
`;

export const TeamTiles = styled(Grid, {
  shouldForwardProp: prop => prop !== 'animation',
})(({ theme, animation }) => ({
  animation: animation ? `${fadeOutIn} 0.5s` : undefined,
  marginTop: theme.spacing(2.5),
}));

export const Image = styled(NextImage)(() => ({
  maxWidth: '100%',
  height: 'auto',
}));
