import React from 'react';

const OpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd" stroke="#0c74c6" strokeWidth="1.697">
      <path d="M19.628 10.934c0 5.032-4.204 9.11-9.39 9.11-5.185 0-9.388-4.078-9.388-9.11 0-5.032 4.203-9.11 9.388-9.11 5.186 0 9.39 4.078 9.39 9.11zM15.063 10.934h-9.65M10.238 15.616V6.252" />
    </g>
  </svg>
);

export default OpenIcon;
