import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Button from '@/components/ui/Button/Button';
import PhoneContact from '@/components/ui/PhoneContact/PhoneContact';
import ReloadButton from '@/components/ui/ReloadButton/ReloadButton';
import {
  trackContactBoxEmailClick,
  trackContactBoxPhoneClick,
} from '@/helpers/dataLayer';
import useFormatMessage from '@/hooks/useFormatMessage';

import burcu from './lib/burcu.jpg';
import franziska from './lib/franziska.jpg';
import ivonne from './lib/ivonne.jpg';
import katrina from './lib/katrina.jpg';
import lars from './lib/lars.jpg';
import lisaMarie from './lib/lisa-marie.jpg';
import marta from './lib/marta.jpg';
import martin from './lib/martin.jpg';
import marzena from './lib/marzena.jpg';
import nam from './lib/nam.jpg';
import nico from './lib/nico.jpg';
import rico from './lib/rico.jpg';
import sebastian from './lib/sebastian.jpg';
import thomas from './lib/thomas.jpg';
import thorsten from './lib/thorsten.jpg';
import tim from './lib/tim.jpg';
import umut from './lib/umut.jpg';
import {
  Headline,
  Image,
  ReloadButtonWrapper,
  TeamTiles,
  Wrapper,
} from './Team.style';

const images = [
  burcu,
  franziska,
  ivonne,
  katrina,
  lars,
  lisaMarie,
  marta,
  martin,
  marzena,
  nam,
  nico,
  rico,
  sebastian,
  thomas,
  thorsten,
  tim,
  umut,
];

const team = images.map((img, index) => ({
  id: `member${index + 1}`,
  img,
}));

/**
 * Team component.
 */

function Team() {
  const [tiles, setTiles] = useState([]);
  const [animation, setAnimation] = useState(false);
  const formatMessage = useFormatMessage();

  const randomizeTiles = () => {
    if (!animation) {
      const randomTiles = team
        .map(el => ({ ...el, rid: Math.random() }))
        .sort((a, b) => a.rid - b.rid)
        .map(({ id, img }) => ({ id, img }))
        .slice(0, 4);
      setAnimation(true);
      setTimeout(() => setTiles(randomTiles), 250);
      setTimeout(() => setAnimation(false), 500);
    }
  };

  useEffect(() => {
    randomizeTiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const email = formatMessage('header_email');
  const emailSubject = formatMessage('header_email_subject');
  const emailBody = formatMessage('header_email_body');
  const emailHref = encodeURI(
    `mailto:${email}?subject=${emailSubject}&body=${emailBody}`
  );

  return (
    <Wrapper>
      <Stack px={4.5} pt={2.5} pb={3}>
        <ReloadButtonWrapper>
          <ReloadButton
            className="mrkt-contact_image-switch"
            onClick={randomizeTiles}
          />
        </ReloadButtonWrapper>
        <Headline align="center" variant="h4" component="div">
          {formatMessage('team_head')}
        </Headline>
        <TeamTiles animation={animation} container spacing={1} columns={2}>
          {tiles.map(({ id, img }) => (
            <Grid item xs={1} key={id} display="flex">
              <Image src={img} alt="A sales team member" />
            </Grid>
          ))}
        </TeamTiles>
        <Box my={4} width="100%">
          <Button
            className="mrkt-contact_mail-kontakt"
            component="a"
            href={emailHref}
            size="large"
            fullWidth
            onClick={trackContactBoxEmailClick}>
            {formatMessage('team_email')}
          </Button>
        </Box>
        <PhoneContact
          headline="team_alt"
          teamAvailability="team_availability"
          onPhoneClick={trackContactBoxPhoneClick}
        />
      </Stack>
    </Wrapper>
  );
}

export default Team;
