import React, { forwardRef } from 'react';

const ReloadButtonIcon = forwardRef(({ className }, ref) => (
  <svg
    ref={ref}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 28 25">
    <g fill="#0c74c6" fillRule="nonzero" stroke="#0c74c6" strokeWidth=".3">
      <path d="M1 18.768l1.705-4.466.721-.302L7.6 15.982c-.288.681-.436 1.02-.443 1.016l-2.924-1.39c1.406 4.36 5.297 7.275 9.764 7.275 4.688 0 8.787-3.272 9.968-7.956.074-.296 1.106-.011 1.031.285C23.697 20.388 19.171 24 13.997 24c-4.932 0-9.219-3.216-10.774-8.03l-1.229 3.211L1 18.768zM26.922 6.275l-1.619 4.713-.71.33-4.158-1.991c.275-.72.416-1.077.423-1.073l2.913 1.396c-1.456-4.538-5.349-7.51-9.768-7.42-4.637.095-8.642 3.604-9.74 8.534-.07.311-1.094.034-1.025-.277C4.445 5.04 8.868 1.165 13.986 1.06c4.88-.1 9.168 3.181 10.779 8.19l1.168-3.387.99.412z" />
    </g>
  </svg>
));

ReloadButtonIcon.displayName = 'ReloadButtonIcon';

export default ReloadButtonIcon;
