import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import usePhoneNumber from '@/hooks/usePhoneNumber';

import {
  Icon,
  PhoneHeader,
  PhoneNumber,
  PhoneNumberWrapper,
  PhoneTextWrapper,
  TeamAvailability,
  Wrapper,
} from './PhoneContact.style';

/**
 * Small Phone Contact section component.
 */
const PhoneContact = ({
  className,
  headline,
  teamAvailability,
  onPhoneClick,
}) => {
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();

  return (
    <Wrapper className={className}>
      <PhoneTextWrapper>
        <Divider sx={{ flex: 1 }}>
          <PhoneHeader variant="body1">
            <FormattedMessage id={headline} />
          </PhoneHeader>
        </Divider>
      </PhoneTextWrapper>
      <PhoneNumberWrapper>
        <PhoneNumber
          variant="body2"
          component="a"
          href={phoneNumberLink}
          onClick={onPhoneClick}>
          <Icon name="phone" />
          <span className="mrsCallTracking">{phoneNumber}</span>
        </PhoneNumber>
      </PhoneNumberWrapper>
      <TeamAvailability variant="label">
        <FormattedMessage id={teamAvailability} />
      </TeamAvailability>
    </Wrapper>
  );
};

PhoneContact.propTypes = {
  /** Css theme. */
  className: PropTypes.string,
  /** Heading text. */
  headline: PropTypes.string.isRequired,
  /** Displayed team availability. */
  teamAvailability: PropTypes.string.isRequired,
  onPhoneClick: PropTypes.func,
};

PhoneContact.defaultProps = {
  className: '',
};

export default PhoneContact;
