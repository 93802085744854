import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import {
  ReloadButtonIcon,
  ReloadButtonWrapper,
  Wrapper,
} from './ReloadButton.style';

const ReloadButton = ({ onClick, className }) => {
  const [rotateButton, setRotateButton] = useState(false);
  const reloadIconRef = useRef();

  useEffect(() => {
    const reloadIconRefValue = reloadIconRef.current;

    if (reloadIconRefValue) {
      reloadIconRefValue.addEventListener('animationend', rotationEnd);
    }

    return () => {
      if (reloadIconRefValue) {
        reloadIconRefValue.removeEventListener('animationend', rotationEnd);
      }
    };
  }, []);

  const rotationStart = () => {
    setRotateButton(true);
  };

  const rotationEnd = () => {
    setRotateButton(false);
  };

  const handleClick = () => {
    rotationStart();
    onClick();
  };

  return (
    <Wrapper className={className} onClick={handleClick}>
      <ReloadButtonWrapper>
        <ReloadButtonIcon ref={reloadIconRef} rotate={rotateButton} />
      </ReloadButtonWrapper>
    </Wrapper>
  );
};

export default ReloadButton;

ReloadButton.propTypes = {
  /** Event listener. */
  onClick: PropTypes.func,
  /** Css theme. */
  className: PropTypes.string,
};

ReloadButton.defaultProps = {
  onClick: () => {},
  className: '',
};
