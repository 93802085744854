import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

export const Wrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const PhoneTextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '85%',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const PhoneHeader = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
}));

export const PhoneNumberWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  borderRadius: 4,
  border: `solid 1px ${theme.palette.primary.main}`,
}));

export const PhoneNumber = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TeamAvailability = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const Icon = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.common.blue,
  position: 'relative',
  marginRight: 4,
}));
