import { Typography } from '@mui/material';
import React, { useState } from 'react';

import { trackFAQClick } from '@/helpers/dataLayer';
import useFormatMessage from '@/hooks/useFormatMessage';

import CloseIcon from './lib/CloseIcon';
import OpenIcon from './lib/OpenIcon';
import {
  Answer,
  Header,
  More,
  MoreText,
  Question,
  QuestionIcon,
  Questions,
  QuestionWrapper,
  Wrapper,
} from './StaticFaq.style';

/**
 * Faq section.
 */
const StaticFaq = () => {
  const [expanded, setExpanded] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const formatMessage = useFormatMessage();
  const count = () => (expanded ? 15 : 10);

  const toggleQuestion = index => {
    const active = index === activeQuestion;
    setActiveQuestion(active ? null : index);
    if (!active) {
      trackFAQClick();
    }
  };

  const renderQuestion = i => {
    const active = i === activeQuestion;
    return (
      <QuestionWrapper key={i} onClick={() => toggleQuestion(i)}>
        <Question>
          <QuestionIcon className="mrkt-faq_open">
            {active ? <CloseIcon /> : <OpenIcon />}
          </QuestionIcon>
          <Typography
            sx={{ cursor: 'pointer' }}
            variant={active ? 'body2' : 'body1'}
            className="mrkt-faq_open">
            {formatMessage(`faq_question_${i + 1}`)}
          </Typography>
        </Question>
        {active && (
          <Answer variant="body1">
            {formatMessage(`faq_answer_${i + 1}`)}
          </Answer>
        )}
      </QuestionWrapper>
    );
  };

  return (
    <Wrapper>
      <Header variant="h2">{formatMessage('faq_header')}</Header>
      <Questions>
        {[...Array(count()).keys()].map(renderQuestion)}
        <More onClick={() => setExpanded(prev => !prev)}>
          <MoreText variant="body2">
            {formatMessage(expanded ? 'faq_expanded' : 'faq_collapsed')}
          </MoreText>
        </More>
      </Questions>
    </Wrapper>
  );
};

export default StaticFaq;
